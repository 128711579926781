'use client'

import React from 'react'

import Link from 'next/link'

import { Box, AppBar as MuiAppBar, Toolbar } from '@mui/material'

import { UserMenu } from './user-menu'

import { GlobalSearch } from '../../autocomplete/global-search'
import { EVTextIcon } from '../../icons'

import { useLanguage } from '../../../hooks/use-language'
import { HEADER_HEIGHT } from '../../../theme'

export const AppBar: React.FC = () => {
    const language = useLanguage()

    return (
        <MuiAppBar
            position="fixed"
            elevation={0}
            sx={(theme) => ({
                background: theme.palette.light.white,
                zIndex: 1,
                borderBottom: `1px solid ${theme.palette.dark.dark8}`,
                minHeight: HEADER_HEIGHT,
            })}>
            <Toolbar sx={{ display: 'flex' }}>
                <Box flex={1} marginRight="auto">
                    <Link href={`/${language}/licence-contracts`}>
                        <EVTextIcon
                            sx={{
                                width: 149,
                                height: 16,
                            }}
                        />
                    </Link>
                </Box>
                <Box flex={1} display="flex" justifyContent="center">
                    <GlobalSearch />
                </Box>
                <Box flex={1} marginLeft="auto" display="flex" justifyContent="flex-end">
                    <UserMenu />
                </Box>
            </Toolbar>
        </MuiAppBar>
    )
}
