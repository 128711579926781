'use client'

import React, { useState } from 'react'

import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack'

import { useTranslation } from '@/lib/i18n'
import { GraphQLErrors } from '@apollo/client/errors'
import { Close } from '@mui/icons-material'
import { Box, Card, CardActions, Collapse, IconButton, Paper, Typography } from '@mui/material'

interface CustomErrorSnackbarProps extends CustomContentProps {
    data: GraphQLErrors | string
}

export const ErrorSnackbar: React.FC<CustomErrorSnackbarProps & { ref: React.Ref<HTMLDivElement> }> = ({ id, ref, ...props }) => {
    const { message, data } = props
    const { t } = useTranslation('common')

    const [expanded, setExpanded] = useState(false)
    const { closeSnackbar } = useSnackbar()

    return (
        <SnackbarContent ref={ref}>
            <Card sx={{ backgroundColor: '#FBE9E8', color: '#5B5959', width: 'calc(100vw - 40px)' }}>
                <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2">{message}</Typography>
                    <Box marginLeft="auto" display="flex" alignItems="center" gap={1}>
                        <Typography
                            variant="body2"
                            sx={{ color: '#5B5959', textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={() => setExpanded(!expanded)}>
                            {expanded ? t('showLess') : t('showMore')}
                        </Typography>

                        <IconButton size="small" sx={{ color: '#5B5959' }} onClick={() => closeSnackbar(id)}>
                            <Close sx={{ fontSize: 18 }} />
                        </IconButton>
                    </Box>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper elevation={0} sx={{ backgroundColor: '#FBE9E8', color: '#5B5959', padding: 1 }}>
                        {typeof data === 'string' ? (
                            <Typography variant="body2">{data}</Typography>
                        ) : (
                            data.map((error, index) => (
                                <React.Fragment key={error.message}>
                                    <Typography variant="body2">{error.message}</Typography>
                                    {index !== data.length - 1 && <br />}
                                </React.Fragment>
                            ))
                        )}
                    </Paper>
                </Collapse>
            </Card>
        </SnackbarContent>
    )
}
