'use client'

import { Session } from 'next-auth'
import { SnackbarProvider } from 'notistack'

import { Authentication } from '@/lib/auth/authentication'
import { AuthenticationProvider } from '@/lib/auth/authentication-provider'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { ApolloProvider } from '../../components/apollo'
import { CloseNotificationButton } from '../../components/close-notification-button'
import { ErrorSnackbar, SuccessSnackbar } from '../../components/snackbars'
import { theme } from '../../theme'

export const Providers: React.FC<{ children: React.ReactNode; session: Session | null }> = ({ children, session }) => {
    return (
        <>
            <SnackbarProvider
                action={(notificationId) => <CloseNotificationButton notificationId={notificationId} />}
                maxSnack={10}
                autoHideDuration={null}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                Components={{
                    customErrorSnackbar: ErrorSnackbar,
                    success: SuccessSnackbar,
                }}>
                <AuthenticationProvider session={session}>
                    <Authentication>
                        <ApolloProvider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
                            </ThemeProvider>
                        </ApolloProvider>
                    </Authentication>
                </AuthenticationProvider>
            </SnackbarProvider>
        </>
    )
}
